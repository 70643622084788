import React from 'react';
import Logo from './Logo';
import style from '../assets/css/Topbar.module.css';

class Topbar extends React.Component {
    render() {
        return (
            <div className={style.div}>
                <Logo text={"Marakuja FUN"}/>
            </div>
        );
    }
}

export default Topbar;