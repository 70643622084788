import React from 'react';
import placeholder from '../assets/svg/question-square.svg';
import style from '../assets/css/Icon.module.css';

class Icon extends React.Component {
    render() {
        return (
            <div className={style.div}>
                <img loading="lazy" src={this.props.icon} style={{width: this.props.size}} className={style.svg} alt={this.props.alt}/>
            </div>
        );
    }
}

Icon.defaultProps = {
    icon: placeholder,
    alt: "Placeholder",
    size: '24px',
};

export default Icon;