import React from 'react';
import style from '../assets/css/Multiplier.module.css';

function Multiplier(props) {
    if (props.selected) {
        return (
            <div className={style.selectedContainer}>
                <p>{props.text}</p>
            </div>
        );
    } else {
        return (
            <div className={style.container} onClick={() => props.changeMultiplier(props.count)}>
                <p>{props.text}</p>
            </div>
        );
    }
}

// Upgrade.defaultProps = {
//     text: "Click Multiplier - 50",
//     count: "x1",
// };


export default Multiplier;