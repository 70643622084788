import React from 'react';
import logo from '../assets/svg/passion.svg';
import style from '../assets/css/Logo.module.css';

class Logo extends React.Component {
    render() {
        return (
            <div className={style.div}>
                <img loading="lazy" src={logo} className={style.svg} alt="logo"/>
                <p className={style.text}>{this.props.text}</p>
            </div>
        );
    }
}

Logo.defaultProps = {
    text: "Marakuja Fun",
};

export default Logo;