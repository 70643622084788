import React from 'react';
import style from '../assets/css/Upgrade.module.css';
import style2 from '../assets/css/disabledUpgrade.module.css';
import Icon from "./Icon";
import lock from "../assets/svg/lock.svg";
import {useTranslation} from "react-i18next";

function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function Upgrade(props) {
    const { t } = useTranslation();

    if (props.disabled || props.requiredUpgrades < props.requiredValue) {
        return (
            <div className={style2.container}>
                <div className={style2.icon}><Icon icon={lock} alt={"lock"} size={"18px"}/></div>

                {/*<p>{props.text}</p>*/}
                <p>{t('Upgrade the previous upgrade count to unlock', { count: props.requiredValue-props.requiredUpgrades })}</p>
            </div>
        );
    }

    if (props._1) {
        return (
            <div className={style.container}>
                <p className={style.count}>x{numberWithSpaces(props.count-1)} {props.sufix}</p>
                <div className={style.textContainer} onClick={() => props.upgrade(props.upgradeName)}>
                    <p>{props.text}</p>
                </div>
            </div>
        );
    } else {
        return (
            <div className={style.container}>
                <p className={style.count}>x{numberWithSpaces(props.count)} {props.sufix}</p>
                <div className={style.textContainer} onClick={() => props.upgrade(props.upgradeName)}>
                    <p>{props.text}</p>
                </div>
            </div>
        );
    }
}

export default Upgrade;