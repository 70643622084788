import Topbar from './components/Topbar';
import Footer from './components/Footer';
import Container from "./components/Container";
import Upgrades from "./components/Upgrades";
import MobileFooter from './components/mobile/Footer';

import './i18n';
import {useEffect, useState} from "react";
import { useMediaQuery } from 'react-responsive'

// eslint-disable-next-line no-unused-vars
import style from './assets/css/Styles.module.css';

function loadData(name, defuault) {
    const savedData = localStorage.getItem("data");
    if (!savedData) {
        return defuault;
    }

    const initialValue = parseInt(JSON.parse(savedData)[name]);
    return initialValue || defuault;
}

function App() {
    const [clicks, setClicks] = useState(loadData("clicks", 0));
    const [clickMultiplier, setClickMultiplier] = useState(loadData("clickMultiplier", 1));
    const [autoClicker, setAutoClicker] = useState(loadData("autoClicker", 1));
    const [autoClickerCooldown, setAutoClickerCooldown] = useState(loadData("autoClickerCooldown", 1));

    const [multiplier, setMultiplier] = useState(1);
    const [upgradesVisible, setUpgradesVisible] = useState(false)

    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1100px)'})

    useEffect(() => {
        let data = {"clicks": clicks, "clickMultiplier": clickMultiplier,
            "autoClicker": autoClicker,  "autoClickerCooldown": autoClickerCooldown};

        localStorage.setItem("data", JSON.stringify(data));

        const intervalId = setInterval(() => {
            setClicks(clicks+(autoClicker-1))
        }, 1000/autoClickerCooldown);

        return () => {
            clearInterval(intervalId);
        };
    }, [clicks, autoClicker, autoClickerCooldown, clickMultiplier]);

    if (window.matchMedia('(display-mode: standalone)').matches) {
        document.querySelector("html").style.touchAction = 'none';
    }

    if (!isDesktopOrLaptop) {
        return (
            <div className="App">
                <Topbar/>

                {!upgradesVisible && (
                    <Container clicks={clicks} setClicks={setClicks}
                               clickMultiplier={clickMultiplier}/>
                )}

                {upgradesVisible && (
                    <Upgrades multiplier={multiplier} setMultiplier={setMultiplier} clicks={clicks} setClicks={setClicks}
                              clickMultiplier={clickMultiplier} setClickMultiplier={setClickMultiplier}
                              autoClicker={autoClicker} setAutoClicker={setAutoClicker}
                              autoClickerCooldown={autoClickerCooldown} setAutoClickerCooldown={setAutoClickerCooldown}/>
                )}

                <MobileFooter upgradesVisible={upgradesVisible} setUpgradesVisible={setUpgradesVisible}
                              setClicks={setClicks} setClickMultiplier={setClickMultiplier}
                              setAutoClicker={setAutoClicker} setAutoClickerCooldown={setAutoClickerCooldown}
                              clicks={clicks}/>
            </div>
        )
    }

    return (
        <div className="App">
            <Topbar/>

            <Container clicks={clicks} setClicks={setClicks}
                       clickMultiplier={clickMultiplier}/>
            <Upgrades multiplier={multiplier} setMultiplier={setMultiplier} clicks={clicks} setClicks={setClicks}
                      clickMultiplier={clickMultiplier} setClickMultiplier={setClickMultiplier}
                      autoClicker={autoClicker} setAutoClicker={setAutoClicker}
                      autoClickerCooldown={autoClickerCooldown} setAutoClickerCooldown={setAutoClickerCooldown}/>


            <Footer setClicks={setClicks} setClickMultiplier={setClickMultiplier}
                    setAutoClicker={setAutoClicker} setAutoClickerCooldown={setAutoClickerCooldown}
                    clicks={clicks}/>
        </div>
    );
}

export default App;
