import React from 'react';
import placeholder from '../assets/svg/question-square.svg';
import style from '../assets/css/ButtonIcon.module.css';
import Icon from './Icon';

class ButtonIcon extends React.Component {
    render() {
        return (
            <div className={style.div} onClick={() => this.props.onClick()}>
                <Icon icon={this.props.icon} alt={this.props.alt}/>
            </div>
        );
    }
}

ButtonIcon.defaultProps = {
    icon: placeholder,
    alt: "Placeholder",
    onClick: function () {

    },
};

export default ButtonIcon;