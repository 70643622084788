import React from 'react';
import Icon from "./Icon";

import logo from '../assets/svg/passion.svg';
import octagon from '../assets/svg/octagon-exclamation.svg';
import style from '../assets/css/Container.module.css';

import {useTranslation} from "react-i18next";

function Container(props) {
    const { t } = useTranslation();

    function handleClick(e) {
        props.setClicks(props.clicks+props.clickMultiplier);
    }

    function numberWithSpaces(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    return (
        <div className={style.wrapper}>
            <div className={style.container}>
                <div className={style.iconContainer}>
                    <img onClick={handleClick} src={logo} className={style.icon} alt="passion fruit" style={{cursor: "pointer"}}/>
                </div>
                <p className={style.count}>{numberWithSpaces(props.clicks)}</p>

                {props.clicks < 10  && (
                    <div className={style.info}>
                        <Icon icon={octagon}/>
                        <p className={style.text}>{t('Click on passion fruit or press space to get points!')}</p>
                        <Icon icon={octagon}/>
                    </div>
                )}

            </div>
        </div>
    );
}

export default Container;