import React from 'react';
import style from '../assets/css/Upgrades.module.css';

import Upgrade from "./Upgrade";
import Multipliers from "./Multipliers";

import {useTranslation} from "react-i18next";
// import ClickMultiplier from "./Upgrades/ClickMultiplier";

function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function Upgrades(props) {
    const { t } = useTranslation();


    const upgrade = (value) => {
        if (value === "ClickMultpilier") {
            if (props.clicks >= props.multiplier*(props.clickMultiplier*50)) {
                props.setClicks(props.clicks-props.multiplier*(props.clickMultiplier*50))
                props.setClickMultiplier(props.clickMultiplier+props.multiplier)

                window.umami.track('"Click Multpilier" upgrade button')
            }
        } else if (value === "AutoClicker") {
            if (props.clicks >= props.multiplier*(props.autoClicker*500)) {
                props.setClicks(props.clicks-props.multiplier*(props.autoClicker*500))
                props.setAutoClicker(props.autoClicker+props.multiplier)
                window.umami.track('"Click Clicker" upgrade button')
            }
        } else if (value === "AutoClickerCooldown") {
            if (props.clicks >= props.multiplier*(props.autoClickerCooldown*25000)) {
                props.setClicks(props.clicks-props.multiplier*(props.autoClickerCooldown*25000))
                props.setAutoClickerCooldown(props.autoClickerCooldown+props.multiplier)
                window.umami.track('"Click Clicker Cooldown" upgrade button')
            }
        }
    };

    return (
        <div className={style.wrapper}>
            <div className={style.container}>
                <div className={style.Topbar}>
                    <header>{t('Upgrades!')}</header>

                    <Multipliers multiplier={props.multiplier} setMultiplier={props.setMultiplier}/>
                </div>

                <div className={style.upgradesContainer}>
                    <Upgrade upgradeName={"ClickMultpilier"}
                                     text={t('Click Multiplier - count', { count: numberWithSpaces(props.multiplier*(props.clickMultiplier*50))})}
                                     count={props.clickMultiplier} upgrade={upgrade}/>


                    <Upgrade upgradeName={"AutoClicker"}
                             text={t('Auto Clicker - count', { count: numberWithSpaces(props.multiplier*(props.autoClicker*500))})}
                             upgrade={upgrade} requiredValue={26} requiredUpgrades={props.clickMultiplier} count={props.autoClicker} _1
                    />

                    <Upgrade upgradeName={"AutoClickerCooldown"}
                             text={t('Auto Clicker Cooldown - count', { count: numberWithSpaces(props.multiplier*(props.autoClickerCooldown*25000))})}
                             upgrade={upgrade} requiredValue={51} requiredUpgrades={props.autoClicker} count={props.autoClickerCooldown} _1
                             sufix={` - ${Math.floor((1/props.autoClickerCooldown)*1000)/1000}s`}
                    />

                </div>
            </div>
        </div>
    );
}

export default Upgrades;