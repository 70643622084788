import React from 'react';
import style from '../assets/css/Select.module.css';

import ReactSelect from 'react-select';

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: null,
        border: 'rgba(0, 0, 0, 0.5) solid 1px',
        borderRadius: '4px',
        fontSize: '14px',
        fontWeight: '300',
        minHeight: '28px',
        height: '28px',
        boxShadow: state.isFocused ? '0 0 0 2px rgba(0, 0, 0, 0.25)' : null,
        '&:hover': {
            borderColor: 'rgba(0, 0, 0, 0) solid 1px',
        },
    }),

    valueContainer: (provided, state) => ({
        ...provided,
        height: '28px',
        padding: '0 6px'
    }),
    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),

    menuList: (provided, state) => ({
        ...provided,
        padding: '0',
    }),

    indicatorSeparator: (provided, state) => ({
        backgroundColor: null,
    }),

    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '28px',
        backgroundColor: null,
    }),

    option: (provided, state) => ({
        ...provided,
        fontSize: '14px',
        fontWeight: '300',
        background: state.isSelected ? 'rgb(58,58,58)' : 'rgb(255,255,255)',
        height: '28px',
        '&:hover': {
            background: 'rgb(194,183,183)',
        },
        '&:active': {
            background: 'rgb(194,183,183)',
        }
    }),
};

function Select(props) {
    // const [selectedOption, setSelectedOption] = useState(null);

    return (
        <div className={style.div}>
            <ReactSelect
                defaultValue={props.defaultValue}
                onChange={props.onChange}
                options={props.options}
                menuPlacement={"top"}
                styles={customStyles}
                isSearchable={false}
            />
        </div>
    );
}

export default Select;